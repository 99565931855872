<template>
  <el-form-item :label="itemData.label" v-bind="[itemData]" :prop="itemKey">
    <template v-if="itemData.style=='select'">
      <el-time-select v-model="itemVal" :picker-options=itemData.pickerOptions @change="change"></el-time-select>
    </template>
    <template v-else>
      <el-time-picker v-model="itemVal" v-bind="[itemData]" @change="change"></el-time-picker>
    </template>
  </el-form-item>
</template>

<script>

export default {
  props: {

    // 表单项配置数据
    itemData: {
      type: Object,
      default: () => {
        return {}
      }
    },

    // 表单键值
    itemKey: String,

    // 父组件双向绑定的model值
    value: {
      type: [Date, String, Array]
    },

    // 当前所在表单组配置项,用于事件函数的参数
    groupData: Object,

    // 表单配置项,用于事件函数的参数
    formData: Array,

    // 表单数据,用于事件函数的参数
    form: Object
  },
  model: {
    // 与父组件进行双向绑定
    prop: 'value',
    event: 'change'
  },
  watch: {
    value() {
      this.itemVal = this.value
    }
  },
  data () {
    return {
      itemVal: this.value
    }
  },
  methods: {
    change(val) {
      if (typeof (this.itemData.changeEvent) === 'function') {
        this.itemData.changeEvent(val, this.form, this.groupData, this.formData)
      }
      this.$emit('change', this.itemVal)
    }
  }
}
</script>
